/**
 * Scripts for Frontend Website
 */

$(document).ready(function () {
    $('.selectpicker').selectpicker();
    tippy('.tip', {
	  theme: 'inspia',
	  position: 'right',
	  animation: 'scale',
	  duration: 200,
	  arrow: true
	});
});
